<template>
  <page-content>

    <common-table
        ref="table"
        path="web/iot/repair"
        search-placeholder="搜索设备MAC/客户名称"
        :queryParams="queryParams"
        :columns="columns">

      <template slot="search">
<!--        <a-button @click="$refs.table.export('报警记录')">导出Excel</a-button>-->
      </template>

      <template slot="type" slot-scope="{record}">
        <a-tag v-if="record.type==1" color="gray">低于最小值</a-tag>
        <a-tag v-else color="cyan">高于最大值</a-tag>
      </template>

    </common-table>


  </page-content>
</template>
<script>
export default {

  data() {
    return {
      getListInterval:null,
      queryParams: {
        // deviceId:this.$route.params.deviceId
      },
      vendorList:[]
    }
  },
  created() {
    this.getListInterval = setInterval(() => {
      this.getList()
    }, 30000)
    this.getVendorList()
  },
  destroyed() {
    clearInterval(this.getListInterval)
  },
  computed: {
    columns() {
      let vendorFilter = [{text: '全部', value: ''}]
      this.vendorList.forEach(item=>{
        vendorFilter.push({text: item.name, value: item.id})
      })
      return [
        {
          title: '经销商',
          dataIndex: 'vendorId',
          filters: vendorFilter,
          filterMultiple: false,
          customRender(text,record){
            return record.vendorName
          }
        },
        {
          title: '设备名称',
          dataIndex: 'deviceName',
        },
        {
          title: '设备MAC',
          dataIndex: 'deviceKey',
        },
        {
          title: '客户名称',
          dataIndex: 'name',
        },
        {
          title: '维修内容',
          dataIndex: 'content',
        },
        {
          title: '时间',
          dataIndex: 'createdAt',
          sorter: true
        },
      ]
    }
  },
  methods: {
    getList() {
      this.$refs.table.getData()
    },
    getVendorList(){
      this.$get('vendor?pageSize=100').then((r) => {
        let data = r.data
        this.vendorList = data.rows
      })
    }

  }
}
</script>
<style lang="less" scoped>

</style>
